@media screen and (max-width: 1125px) {


    .default .screen.intro .fixed-header, 
    .asu .screen.intro .fixed-header {
        justify-content: flex-end;
    }

    .default .screen.intro .fixed-header .login,
    .asu .screen.intro .fixed-header .login {
        max-width: unset;
        margin: unset;
        padding: unset;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px 30px 30px 30px;
    }

    .default .screen.intro .section.address-bar {
        height: 650px;
        max-height: unset;
        background-image: url('../../assets/imgs/top-cover-mobile.png');
    }

    .asu .screen.intro .section.address-bar {
        height: 1044px;
        max-height: unset;
        background-image: url('../../assets/imgs/top-cover-asu-mobile.png');
        background-size: cover;
    }

    .default .screen.intro .section.address-bar .logo {
        margin-top: 70px;
        height: 40px;
        width: 200px;
        background-size: contain;
    }

    .asu .screen.intro .section.address-bar .logo {
        margin-top: 40px;
        height: 80px;
        width: 300px;
        background-size: contain;
        background-image: url('../../assets/imgs/logo-asu-mobile.png');
        background-position: center;
    }
    
    .default .screen.intro .section.address-bar .title,
    .asu .screen.intro .section.address-bar .title {
        width: 90%;
        font-size: 45px;
    }
    
    .default .screen.intro .section.address-bar .title.first-line,
    .asu .screen.intro .section.address-bar .title.first-line {
        margin-top: 30px;
    }
    
    .default .screen.intro .section.address-bar .sub-title,
    .asu .screen.intro .section.address-bar .sub-title {
        margin-top: 17px;
        width: 90%;
      }
    
    .default .screen.intro .section.address-bar .address-form,
    .asu .screen.intro .section.address-bar .address-form,
    .default .screen.intro .section.address-bar .lp-form,
    .asu .screen.intro .section.address-bar .lp-form {
        width: 90%;
        height: 50px;
    }
    
    .default .screen.intro .section.address-bar .address-form .text-field,
    .asu .screen.intro .section.address-bar .address-form .text-field {
        margin: 0;
        padding: 0;
        height: 100%;
        width: unset;
        flex: 1;
    }

    .default .screen.intro .section.address-bar .address-form .text-field input,
    .asu .screen.intro .section.address-bar .address-form .text-field input {
        height: 50px;
        width: 100%;
        padding: 8px;
        font-size: 16px;
    }
    
    .default .screen.intro .section.address-bar .address-form .button {
        height: 100%;
        width: 126px;
        /* background-image: url('../../assets/imgs/start.png');
        background-size: contain; */
        font-size: 14px;
    }

    .asu .screen.intro .section.address-bar .address-form .button {
        height: 100%;
        width: 156px;
        background-image: url('../../assets/imgs/start-asu.png');
        background-size: contain;
    }

    .default .screen.intro .section.address-bar .lp-form,
    .asu .screen.intro .section.address-bar .lp-form {
        margin-top: 20px;
    }

    .default .screen.intro .section.address-bar .lp-form .button,
    .asu .screen.intro .section.address-bar .lp-form .button {
        padding: 10px;
    }
    
    .default .screen.intro .section.testimonials , 
    .asu .screen.intro .section.testimonials {
        margin-top: -120px;
        padding-top: 30px;
    }
    
    .default .screen.intro .section.testimonials .title,
    .asu .screen.intro .section.testimonials .title {
        width: 90%;
        margin-top: 0px;
        font-size: 44px;
    }
    
    .default .screen.intro .section.testimonials .testimonial,
    .asu .screen.intro .section.testimonials .testimonial {
        width: 100%;
        max-width: unset;
        height: unset;
        display: flex;
        flex-direction: column;
        margin-top: 30px;
    }
    
    .default .screen.intro .section.testimonials .testimonial .player,
    .asu .screen.intro .section.testimonials .testimonial .player {
        width: 100%;
        height: auto;
    }

    .default .screen.intro .section.testimonials .testimonial .player .player-button,
    .asu .screen.intro .section.testimonials .testimonial .player .player-button {
        height: 100px;
        width: 100px;
        background-size: contain;
    }
    
    .default .screen.intro .section.testimonials .testimonial .content,
    .asu .screen.intro .section.testimonials .testimonial .content {
        width: 100%;
        height: unset;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: unset;
        padding-top: 37px;
        padding-bottom: 37px;
    }
    
    .default .screen.intro .section.testimonials .testimonial .content .text,
    .asu .screen.intro .section.testimonials .testimonial .content .text {
        margin-top: 37px;
        font-size: 24px;
        width: 90%;
        text-align: center;
    }
    
    .default .screen.intro .section.testimonials .reviews,
    .asu .screen.intro .section.testimonials .reviews {
        width: 100%;
        max-width: 1586px;
        margin-top: 0px;
        margin-bottom: 56px;
    }
    
    .default .screen.intro .section.testimonials .reviews .review .text,
    .asu .screen.intro .section.testimonials .reviews .review .text {
        font-size: 24px;
    }
    
    .default .screen.intro .section.testimonials .reviews .logos,
    .asu .screen.intro .section.testimonials .reviews .logos {
        margin-top: 0px;
        flex-direction: column;
    }
    
    .default .screen.intro .section.testimonials .reviews .logos .google-logo,
    .asu .screen.intro .section.testimonials .reviews .logos .google-logo {
        height: 78px;
        width: 250px;
        background-size: cover;

    }

    .default .screen.intro .section.testimonials .logos .best-company a img,
    .asu .screen.intro .section.testimonials .logos .best-company a img {
        height: 150px;
        margin-top: 20px;
    }
    
    .default .screen.intro .section.testimonials .reviews .score .score-content span:nth-child(1),
    .asu .screen.intro .section.testimonials .reviews .score .score-content span:nth-child(1){
        font-size: 40px;
    }
    
    .default .screen.intro .section.testimonials .reviews .score .score-content span:nth-child(2),
    .asu .screen.intro .section.testimonials .reviews .score .score-content span:nth-child(2){
        font-size: 12px;
    }
    
    .default .screen.intro .section.testimonials .reviews .p-carousel-items-content,
    .asu .screen.intro .section.testimonials .reviews .p-carousel-items-content {
        margin-left: unset;
        margin-right: unset;
    }
    
    .default .screen.intro .section.why-solar,
    .asu .screen.intro .section.why-solar {
        background-color: #fff;
        margin-bottom: 108px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .default .screen.intro .section.why-solar .title,
    .asu .screen.intro .section.why-solar .title {
        margin-top: 50px;
        font-size: 34px;
    }
    
    .default .screen.intro .section.why-solar .sub-title,
    .asu .screen.intro .section.why-solar .sub-title {
        width: 85%;
        margin-top: 32px;
    }
    
    .default .screen.intro .section.why-solar .p-accordion,
    .asu .screen.intro .section.why-solar .p-accordion  {
        margin-top: 58px;
        width: 85%;
    }
    
    .default .screen.intro .section.why-solar .p-accordion-tab,
    .asu .screen.intro .section.why-solar .p-accordion-tab  {
        margin-bottom: 24px;
        padding-bottom: 24px;
    }
    
    .default .screen.intro .section.why-solar .p-toggleable-content,
    .asu .screen.intro .section.why-solar .p-toggleable-content {    
        margin-top: 24px;
        font-family: Inter;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: -0.26px;
        color: #06285e;
    }
    
    .default .screen.intro .section.why-solar .p-accordion-header-link,
    .asu .screen.intro .section.why-solar .p-accordion-header-link {
        font-size: 24px;
    }
    
    .default .screen.intro .section.why-solar .p-accordion-toggle-icon,
    .asu .screen.intro .section.why-solar .p-accordion-toggle-icon {
        margin-left: 0px;
    }
}