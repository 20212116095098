.screen.about-the-property {
    background-color: #06285e;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.screen.about-the-property .section.title {
    margin-top: 121px;
    font-family: SpaceGrotesk;
    font-size: 37.5px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: -0.38px;
    text-align: center;
    color: #fff;
    width: 94%;
}

.screen.about-the-property .section.subtitle {
    margin-top: 17px;
    font-family: Inter;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.18px;
    text-align: center;
    color: #80c0ff;
}

.screen.about-the-property .section.question {
    margin-top: 84px;
    border-bottom: 1px solid #80c1ff;
    width: 94%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.screen.about-the-property.not-arizona .section.question {
    border-bottom: unset;
}

.screen.about-the-property .section.question .question-text {
    font-family: Inter;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.24px;
    text-align: center;
    color: #fff;
}

.screen.about-the-property .section.question .question-text.upload-link {
    cursor: default;
    text-decoration: none;
    margin-bottom: 20px;
    max-width: 50vw;
}

.screen.about-the-property.not-arizona .section.question .question-text.upload-link {
    display: none;
}

.screen.about-the-property .section.question .question-description, 
.screen.about-the-property .section.question .question-description a,
.screen.about-the-property .section.question .question-description a:visited {
    margin-top: 13px;
    font-family: Inter;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.18px;
    text-align: center;
    color: #80c0ff;
    max-width: 631px;
}

.screen.about-the-property .section.question .question-description.uploaded {
    display: flex;
    flex-direction: column;
} 

.screen.about-the-property .section.question .question-description.uploaded .icons {
    
}

.screen.about-the-property .section.question .question-description.uploaded .icons .pi {
    margin: 20px;
    font-size: 30px;
    cursor: pointer;
}

.screen.about-the-property .section.question .buttons {
    display: flex;
    flex-direction: row;
}

.screen.about-the-property .section.question .buttons .button {
    margin-top: 22px;
    width: 156px;
    height: 46px;
    background-color: #004fc3;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: SpaceGrotesk;
    font-size: 17.5px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 4.54;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
}

.screen.about-the-property .section.question .buttons .button i {
    display: none;
}

.screen.about-the-property.not-arizona .section.question .buttons .button {
    flex-direction: column;
    background-color: #06285e;    
    height: 120px;    
}

.screen.about-the-property.not-arizona .section.question .buttons .button i {
    display: block;
    font-size: 40px;
    font-weight: bold;
}

.screen.about-the-property.not-arizona .section.question .buttons .button span {
    /* display: none; */
}


.screen.about-the-property .section.question .buttons .button.notset {
    opacity: 0.5;
}

.screen.about-the-property .section.question .buttons .button.set {
    opacity: 1;
}

.screen.about-the-property .section.question .buttons .button.yes {
    margin-right: 10px;
}

.screen.about-the-property.not-arizona .section.question .buttons .button.set.yes {
    color: #81d553;
}

.screen.about-the-property.not-arizona .section.question .buttons .button.set.no {
    color: red;
}


.screen.about-the-property .section.question .batteries-detail-question-options {
    margin-top: 21px;
    display: flex;
    flex-direction: row;
}

.screen.about-the-property .section.question .continue-button {
    margin-top: 20px;
    background-color: #81D553;
    width: 200px;
    padding: 8px 16px;
    font-family: Inter;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.78;
    letter-spacing: -0.2px;
    text-align: left;
    color: #06285e;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.screen.about-the-property .section.question .batteries-detail-question-options  .batteries-detail-question-option {
    border-right: 1px solid #80c1ff;
    display: flex;
    flex-direction: column;
    padding: 33px;
    justify-content: flex-start;
    align-items: center;
    flex: 1
}

.screen.about-the-property .section.question .batteries-detail-question-options  .batteries-detail-question-option input[type='radio']{
    width: 30px;
    height: 30px;
}

.screen.about-the-property .section.question .batteries-detail-question-options  .batteries-detail-question-option:last-child {
    border: none;;
}

.screen.about-the-property .section.question .batteries-detail-question-options  .batteries-detail-question-option .label {
    margin-top: 16px;
    font-family: Inter;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.18px;
    text-align: center;
    color: #fff;
}

.screen.about-the-property .section.question .batteries-detail-question-options  .batteries-detail-question-option .content {
    margin-top: 16px;
    font-family: Inter;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.18px;
    text-align: center;
    color: #80c0ff;
}

.screen.about-the-property .section.question.upload {
    padding-bottom: 50px;
} 

.screen.about-the-property .section.question .eletric-bill-fields {
    margin-top: 23px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 57px;
}

.screen.about-the-property .section.question .eletric-bill-fields input {
    width: 322px;
    height: 46px;
    font-family: Inter;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.78;
    letter-spacing: -0.2px;
    text-align: left;
    color: #06285e;
}

.screen.about-the-property .section.question .eletric-bill-fields .hint {
    margin-top: 23px;
    max-width: 299px;
    font-family: Inter;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.18px;
    text-align: center;
    color: #80c1ff;
}

.screen.about-the-property .section.buttons {
    margin-top: 51px;   
    margin-bottom: 51px;   
}

.screen.about-the-property .section.buttons .submit-button {
    background-image: url('../../assets/imgs/submit.png');
    background-repeat: no-repeat;
    object-fit: contain;
    width: 322px;
    height: 46px;
    cursor: pointer;
}

.screen.about-the-property .section.buttons .error-message {
    margin-top: 12px;
    font-family: Inter;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.18px;
    text-align: center;
    color: red;
}