.screen.quote {

}

.screen.quote .section.main {
    height: 939px;
    background-image: url('../../assets/imgs/top-back.png');
    background-repeat: no-repeat;
    background-position: top;   
    background-size: cover;
    background-color: #80c1ff;
    display: flex;
    flex-direction: column;
    padding-left: 165px;
    padding-right: 165px;
    padding-top: 59px;
}

.screen.quote  .section.main .title {
    font-family: Inter;
    font-size: 28px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.71;
    letter-spacing: -0.28px;
    text-align: left;
    color: #fff;
}

.screen.quote .section.main .sub-title {
    margin-top: 17px;
    max-width: 558px;
    font-family: Inter;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.18px;
    text-align: left;
    color: #80c0ff;
}

.screen.quote .section.main .widgets {
    display: flex;
    flex-direction: row;
    margin-top: 55px;
    width: 100%;
}

.screen.quote .section.main .widgets .widget{
    flex: 1;
    padding: 34px 26px 30px 24px;
    border-radius: 24px;
    box-shadow: 0px 14px 27.6px 2.4px rgba(0, 56, 124, 0.4);
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 10px;
}

.screen.quote .section.main .widgets .widget .widget-title {
    font-family: Inter;
    font-size: 28px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.28px;
    text-align: left;
    color: #0052ca;
}

.screen.quote .section.main .widgets .widget.property-details .address {
    margin-top: 22px;
    font-family: Inter;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.18px;
    text-align: left;
    color: #06285e;
}

.screen.quote .section.main .widgets .widget.property-details #map{
    width: 100%;
    height: 240px;
    margin-top: 17px;
    border-radius: 17px;
}

.screen.quote .section.main .widgets .widget.property-details .average-montly-bill {
    margin-top: 23px;
    font-family: Inter;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.18px;
    text-align: left;
    color: #06285e;
}

.screen.quote .section.main .widgets .widget.property-details .average-montly-bill-actions {
    font-family: Inter;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.18px;
    text-align: left;
    color: #06285e;
    display: flex;
}

.screen.quote .section.main .widgets .widget.property-details .average-montly-bill-actions .average-montly-bill-action {
    margin-right: 10px;
}

.screen.quote .section.main .widgets .widget.property-details .average-montly-bill-actions .average-montly-bill-action.action {
    text-decoration: underline;
    cursor: pointer;
}

.screen.quote .section.main .widgets .widget.property-details .average-montly-bill-actions.valuechange {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.screen.quote .section.main .widgets .widget.property-details .average-montly-bill-actions.valuechange .links {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.screen.quote .section.main .widgets .widget.property-details .savings {
    padding: 21px 20px 18px 22px;
    margin-top: 26px;
    object-fit: contain;
    border-radius: 17px;
    background-color: #81d553;
    font-family: Inter;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.18px;
    text-align: left;
    color: #06285e;
    display: flex;
    justify-content: space-between;
}

.screen.quote .section.main .widgets .widget.property-details .savings .value {
    font-weight: bolder;
}

.screen.quote .section.main .widgets .widget.estimated-savings .saving-label {
    margin-top: 22px;
    font-family: Inter;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.18px;
    text-align: left;
    color: #06285e;
}

.screen.quote .section.main .widgets .widget.estimated-savings .saving-value {
    margin-top: 8px;
    padding-bottom: 8px;
    font-family: Inter;
    font-size: 28px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.86;
    letter-spacing: -0.28px;
    text-align: left;
    color: #0052cb;
    border-bottom: 2px solid #80c1ff;
}

.screen.quote .section.main .widgets .widget.estimated-savings .saving-disclaimer {
    font-family: Inter;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: -0.16px;
    text-align: left;
    color: #06285e;
    margin-top: 20px;
}

.screen.quote .section.main .widgets .widget.advisor .advisor-panel {
    border-radius: 17px;
    background-color: #06285e;
    display: flex;
    flex-direction: row;
    padding-top: 20px;
    padding-left: 20px;
    margin-top: 20px;
}

.screen.quote .section.main .widgets .widget.advisor .advisor-panel .advisor-picture {
    width: 151px;
    height: 151px;
    background-image: url('../../assets/imgs/advisor.png');
    background-repeat: no-repeat;
}

.screen.quote .section.main .widgets .widget.advisor .advisor-panel .advisor-name {
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: -0.24px;
    text-align: left;
    color: #fff;
    display: flex;
    padding-top: 30px;
    padding-right: 20px;
}

.screen.quote .section.main .widgets .widget.advisor .advisor-message {
    font-family: Inter;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.18px;
    text-align: left;
    color: #06285e;
    margin-top: 24px;
}

.screen.quote .section.main .widgets .widget:last-child{
    width: 100%;
    height: unset;
    margin-right: 17px;
    padding: unset;
    border-radius: 24px;
    box-shadow: 0px 14px 27.6px 2.4px rgba(0, 56, 124, 0.4);
    background-color: unset;
    display: flex;
    flex-direction: column;
    margin-right: 0px;
}

.screen.quote .section.main .widgets .widget .widget-buttom {
    width: 100%;
    padding: 21px 20px 18px 22px;
    margin-top: 10px;
    object-fit: contain;
    border-radius: 17px;
    background-color: #81d553;
    font-family: Inter;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.18px;
    text-align: center;
    color: #06285e;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    font-weight: bolder;
    cursor: pointer;
}

.screen.quote .section.main .widgets .widget .widget-buttom a,
.screen.quote .section.main .widgets .widget .widget-buttom a:visited {
    color: #06285e;
    text-decoration: none;
}

.screen.quote .section.main .widgets .widget .widget-buttom.blue {
    background-color: #80c1ff;
}

.screen.quote .section.main .widgets .widget.more .widget-child.next {
    width: 100%;
    padding: 34px 48px 52px 35px;
    border-radius: 24px;
    box-shadow: 0px 14px 27.6px 2.4px rgba(0, 56, 124, 0.4);
    background-color: #ede74e;
    margin-bottom: 10px;

}

.screen.quote .section.main .widgets .widget.more .widget-child.next .content {
    margin-top: 22px;
    font-family: Inter;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.18px;
    text-align: left;
    color: #06285e;
}

.screen.quote .section.main .widgets .widget.more .widget-child.password {
    width: 100%;
    padding: 34px 48px 52px 35px;
    border-radius: 24px;
    box-shadow: 0px 14px 27.6px 2.4px rgba(0, 56, 124, 0.4);
    background-color: #0052cb;
}

.screen.quote .section.main .widgets .widget.more .widget-child.password .widget-title {
    color: #fff;
}

.screen.quote .section.main .widgets .widget.more .widget-child.password .content {
    margin-top: 22px;
    font-family: Inter;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.18px;
    text-align: left;
    color: #80c1ff;
}

.screen.quote .section.main .widgets .widget.more .widget-child.password .forms {
    margin-top: 38px;
    height: 59px;
    border-radius: 17px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
}

.screen.quote .section.main .widgets .widget.more .widget-child.password .forms .p-password {
    border-radius: 17px;
    border: none;
    width: unset;
    background-color: #fff;
    flex: 1;
}

.screen.quote .section.main .widgets .widget.more .widget-child.password .forms .p-password input {
    width: 100%;
    border: none;
    border-radius: 17px;
}

input:focus {
    outline: none;;
}

.screen.quote .section.main .widgets .widget.more .widget-child.password .forms .p-password input {
    outline: none;
}

.screen.quote .section.main .widgets .widget.more .widget-child.password .forms .button {
    width: 96px;
    background-image: url('../../assets/imgs/set.png');
    background-repeat: no-repeat;
    cursor: pointer;
}

.screen.quote .section.other-offers {
    background-color: #80c1ff;
    margin-top: -220px;
    padding-top: 140px;
    display: flex;
    padding-left: 165px;
    padding-right: 165px;
    justify-items: center;
    align-items: center;
    justify-content: center;
}

.screen.quote .section.other-offers .offers {
    display: flex;
    flex-direction: column;
    padding-bottom: 61px;
}

.screen.quote .section.other-offers .offers.deal {
    padding-right: 34px;
    border-right: 1px solid #06285e;
}

.screen.quote .section.other-offers .offers.referrals {
    padding-left: 34px;
    max-width: 50%;
    cursor: pointer;
}

.screen.quote .section.other-offers .offers .title {
    font-family: Inter;
    font-size: 28px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.71;
    letter-spacing: -0.28px;
    text-align: left;
    color: #06285e;
}

.screen.quote .section.other-offers .offers .content {
    display: flex;
    margin-top: 27px;
}

.screen.quote .section.other-offers .offers .content .image {
    margin-right: 24px;
}

.screen.quote .section.other-offers .offers.deal .content .image {
    width: 86px;
    height: 49px;
    background-image: url('../../assets/imgs/deal-icon.png');
    background-repeat: no-repeat;
}

.screen.quote .section.other-offers .offers.referrals .content .image {
    width: 87px;
    height: 47px;
    background-image: url('../../assets/imgs/refer-icon.png');
    background-repeat: no-repeat;
    background-size: contain;
}

.screen.quote .section.other-offers .offers .content .text {
    font-family: Inter;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.18px;
    text-align: left;
    color: #06285e;
}