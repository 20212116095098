.asu .screen.intro {
    
}

.asu .screen.intro .fixed-header {
    position: fixed;
    width: 100%;
    max-width: 1920px;
    display: flex;
    justify-content: flex-end;
}

.asu .screen.intro .fixed-header .login {
    background-color: none;
    width: 100%;
    max-width: 397px;
    margin-right: 166px;
    padding: 67px 32px 30px 32px;
}

.asu .screen.intro .fixed-header .login.open {
    background-color: #fff;
    box-shadow: 0px 15px 31.5px 3.5px rgba(6, 40, 94, 0.2);
}

.asu .screen.intro .fixed-header .login .title {
    font-family: Inter;
    font-size: 16.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #8b0942;
    cursor: pointer;
    margin-bottom: 10px;
}

.asu .screen.intro .fixed-header .login.open .title {
    color: #8b0942;
}

.asu .screen.intro .fixed-header .login .title .pi {
    margin-left: 5px;
    color: #fff;
}

.asu .screen.intro .fixed-header .login.open .title .pi {
    color: #8b0942;
}

.asu .screen.intro .fixed-header .login .p-inputtext {
    width: 100%;
    margin-top: 10px;
    border-radius: unset;
    border: solid 1px #8b0942;
    font-family: Inter;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.78;
    letter-spacing: -0.2px;
    text-align: left;
    color: #8b0942;
}

.asu .screen.intro .fixed-header .login .p-password {
    width: 100%;
}

.asu .screen.intro .fixed-header .login .button {
    margin-top: 10px;
    height: 46px;
    width: 100%;
    background-image: url('../../assets/imgs/login-asu.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.asu .screen.intro .fixed-header .login .button.disabled {
    opacity: 0.5;
    cursor: default;
}

.asu .screen.intro .fixed-header .login .error-message {
    color: red;
    margin-top: 10px;
}

.asu .screen.intro .section.address-bar {
    height: 680px;
    background-image: url('../../assets/imgs/top-cover-asu.png');
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.asu .screen.intro .section.address-bar .logo {
    margin-top: 102px;
    height: 65px;
    width: 641px;
    background-image: url('../../assets/imgs/logo-asu.png');
    background-repeat: no-repeat;
}

.asu .screen.intro .section.address-bar .title {
    font-family: SpaceGrotesk;
    font-size: 65px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -1.3px;
    text-align: center;
    
}

.asu .screen.intro .section.address-bar .title.first-line {
    margin-top: 86px;
    color: #8b0942;
}

.asu .screen.intro .section.address-bar .title.second-line {
    margin-top: unset;
    color: #000;
}

.asu .screen.intro .section.address-bar .sub-title {
    margin-top: 34px;

    font-family: Inter;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.26px;
    text-align: center;
    color: #000;
  }

.asu .screen.intro .section.address-bar .address-form {
    margin-top: 27px;
    width: 809px;
    height: 62px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.asu .screen.intro .section.address-bar .address-form .text-field {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 593px;
}

.asu .screen.intro .section.address-bar .address-form .text-field input {
    border: none;
    width: 100%;
    height: 60px;
    padding-left: 18px;
    font-family: Inter;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.26px;
    text-align: left;
    color: #000;
}

.asu .screen.intro .section.address-bar .address-form .text-field input:disabled {
    background-color: white;
    color: #000;
}

.asu .screen.intro .section.address-bar .address-form .text-field input:focus {
    outline: none;
}

.asu .screen.intro .section.address-bar .address-form .button {
    height: 62px;
    width: 216px;
    background-image: url('../../assets/imgs/get-started-asu.png');
    background-repeat: no-repeat;
    cursor: pointer;
}

.asu .screen.intro .section.address-bar .address-form .button.disabled {
    opacity: 0.8;
    cursor: default;
}

.asu .screen.intro .section.address-bar .lp-form {
    width: 809px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.asu .screen.intro .section.address-bar .lp-form .field{
    display: flex;
    flex-direction: column;
}

.asu .screen.intro .section.address-bar .lp-form .title {
    margin-top: 10px;
    font-family: Inter;
    font-size: 24px;
    font-weight: bolder;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.26px;
    text-align: left;
    color: #000;
}

.asu .screen.intro .section.address-bar .lp-form .text_field {
    margin: 0;
    margin-top: 5px;
    padding: 0;
    height: 100%;
}

.asu .screen.intro .section.address-bar .lp-form .text_field input {
    border: none;
    width: 100%;
    height: 60px;
    font-family: Inter;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.26px;
    text-align: left;
    color: #000;
}

.asu .screen.intro .section.address-bar .lp-form .button {
    margin-top: 30px;
    width: 100%;
    height: 60px;
    font-family: Inter;
    font-size: 24px;
    font-weight: bolder;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.26px;
    text-align: left;
    color: #8b0942;
    background-color: #ffc937;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    border-radius: 5px;
}

.error_message {
    font-family: Inter;
    font-size: 16px;
    font-weight: bolder;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.26px;
    text-align: left;
    color: red;
}

.asu .screen.intro .section.address-bar .lp-form .button span {
    margin-left: 18px;
    margin-right: 18px;
}

.asu .screen.intro .section.testimonials {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0px;
    background-color: #8b0942;
}

.asu .screen.intro .section.testimonials .title {
    font-family: 'SpaceGrotesk';
    font-size: 54px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: -1.08px;
    text-align: center;
    color: #ffc937;
}

.asu .screen.intro .section.testimonials .testimonial {
    width: 1586px;
    background-color: #ffc937;
    height: 615px;
    display: flex;
    flex-direction: row;
    margin-top: 65px;
    margin-bottom: 56px;
}

.asu .screen.intro .section.testimonials .testimonial .player {
    width: 1093px;
    height: 615px;
    background-color: gray;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.asu .screen.intro .section.testimonials .testimonial .player .player-button {
    height: 149px;
    width: 149px;
    background-image: url('../../assets/imgs/play-button-asu.png');
    background-repeat: no-repeat;
    cursor: pointer;
    position: absolute;
    z-index: 10000;
}

.asu .screen.intro .section.testimonials .testimonial .content {
    width: calc(1586px - 1093px);
    height: 615px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 57px;
}

.asu .screen.intro .section.testimonials .testimonial .content .quote-img {
    height: 30px;
    width: 39px;
    background-image: url('../../assets/imgs/quote-symbol.png');
    background-repeat: no-repeat;
}

.asu .screen.intro .section.testimonials .testimonial .content .text {
    margin-top: 31px;
    font-family: Inter;
    font-size: 36px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: -0.4px;
    text-align: left;
    color: #8b0942;
}

.asu .screen.intro .section.testimonials .testimonial .content .reference {
    margin-top: 35px;
    font-family: Inter;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.26px;
    text-align: left;
    color: #000;
}

.asu .screen.intro .section.testimonials .reviews {
    width: 90%;
    max-width: 1586px;
    margin-bottom: 56px;
}

.asu .screen.intro .section.testimonials .reviews .review {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.asu .screen.intro .section.testimonials .reviews .text {
    font-family: Inter;
    font-size: 36px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: -0.4px;
    text-align: center;
    color: #fff;
}

.asu .screen.intro .section.testimonials .reviews .author {
    margin-top: 26px;
    font-family: Inter;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.26px;
    text-align: center;
    color: #ffc937;
    text-transform: uppercase;
}

.asu .screen.intro .section.testimonials .reviews .review .stars .pi {
    font-size: 32px;
    margin: 10px;
    color: #eec137;
}

.asu .screen.intro .section.testimonials .logos {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 57px;

}

.asu .screen.intro .section.testimonials .logos .google-logo {
    height: 104px;
    width: 333px;
    background-image: url('../../assets/imgs/google.png');
    background-repeat: no-repeat;
}

.asu .screen.intro .section.testimonials .logos .best-company a img {
    height: 200px;
}

.asu .screen.intro .section.testimonials .reviews .p-carousel-items-content {
    margin-left: 100px;
    margin-right: 100px;
}

.asu .screen.intro .section.testimonials .reviews .p-carousel-indicators {
    display: none;
}

.asu .screen.intro .section.testimonials .reviews .p-carousel-prev-icon {
    color: #ffc937;
    font-size: 28px
}

.asu .screen.intro .section.testimonials .reviews .p-carousel-next-icon {
    color: #ffc937;
    font-size: 28px;
}


.asu .screen.intro .section.why-solar {
    background-color: #fff;
    margin-bottom: 108px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.asu .screen.intro .section.why-solar .title {
    margin-top: 95px;
    font-family: 'SpaceGrotesk';
    font-size: 54px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: -1.08px;
    text-align: left;
    color: #8b0942;
}

.asu .screen.intro .section.why-solar .sub-title {
    max-width: 1105px;
    margin-top: 36px;
    font-family: Inter;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.26px;
    text-align: center;
    color: #000;
}

.asu .screen.intro .section.why-solar .p-accordion  {
    margin-top: 78px;
    width: 50vw;
    max-width: 889px;
}

.asu .screen.intro .section.why-solar .p-accordion-tab  {
    border-bottom: 2px solid #ffc937;
    margin-bottom: 24px;
    padding-bottom: 24px;
}

.asu .screen.intro .section.why-solar .p-toggleable-content {    
    margin-top: 24px;
    font-family: Inter;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.26px;
    color: #000;
}

.asu .screen.intro .section.why-solar .p-accordion-header-link {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #8b0942;
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: -0.26px;
    text-align: left;
    color: #8b0942;
    border: none;
    background-color: #fff;
}

.asu .screen.intro .section.why-solar .p-accordion-toggle-icon {
    color: #8b0942;
    order: 2;
}


