.wizard-steps {
    width: 100%;
    margin-top: 20px
}

.wizard-steps .p-steps .p-steps-item .p-menuitem-link {
    background-color: transparent !important;
}

.wizard-steps .p-steps .p-steps-item.p-highlight .p-steps-number {
    color: #06285e;
    background-color: #81D553;
    border-color: #81D553;
}

.wizard-steps .p-steps .p-steps-item.p-highlight .p-steps-title {
    color: #81D553;
}

.wizard-steps .p-steps .p-steps-item.p-disabled .p-steps-number {
    color: #06285e;
    background-color: #fff;
    border-color: #fff;
}

.wizard-steps .p-steps .p-steps-item.p-disabled .p-steps-title {
    color: #fff;
}

@media screen and (max-width: 1125px) {

    .wizard-steps .p-steps .p-steps-item .p-steps-title {
        display: none;
    }

    .wizard-steps .p-steps .p-steps-item:before {
        border-width: 0px !important;
    }
}

