#form-file-upload {
    height: unset;
    width: 28rem;
    max-width: 90%;
    text-align: center;
    position: relative;
    background-color: #cbd5e1;
    padding: 5px;
    border-radius: 10px;
  }
  
  #input-file-upload {
    display: none;
  }
  
  #label-file-upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 10px;
    border-style: dashed;
    border-color: #cbd5e1;
    background-color: #f8fafc;
    padding: 20px;
  }

  #label-file-upload .title {
    font-family: Inter;
    font-weight: bold;
    font-size: 20px;
    color: #06285e;
  }

  #label-file-upload .subtitle {
    font-family: Inter;
    font-weight: normal;
    font-size: 16px;
  }
  
  #label-file-upload.drag-active {
    background-color: #ffffff;
  }
  
  .upload-button {
    cursor: pointer;
    padding: 0.25rem;
    font-size: 1rem;
    border: none;
    font-family: 'Oswald', sans-serif;
    background-color: transparent;
  }
  
  .upload-button:hover {
    text-decoration-line: underline;
  }
  
  #drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }