@media screen and (max-width: 1125px) {
    /* .screen.about-the-property {
        background-color: #06285e;
        display: flex;
        flex-direction: column;
        align-items: center;
    } */

    .screen.about-the-property .section.title {
        margin-top: 40px;
        font-size: 32px;
        /* 
        font-family: SpaceGrotesk;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.13;
        letter-spacing: -0.38px;
        text-align: center;
        color: #fff;
        width: 94%; */
    }

    /* .screen.about-the-property .section.subtitle {
        margin-top: 17px;
        font-family: Inter;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: -0.18px;
        text-align: center;
        color: #80c0ff;
    } */

    .screen.about-the-property .section.question {
        margin-top: 44px;
        width: 100%;
        /* 
        border-bottom: 1px solid #80c1ff;
        
        display: flex;
        flex-direction: column;
        align-items: center; */
    }

    .screen.about-the-property .section.question .question-text {
        width: 90%;
        /* font-family: Inter;
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: -0.24px;
        text-align: center;
        color: #fff; */
    }

    .screen.about-the-property .section.question .question-text.upload-link {
        max-width: unset;
    }

    .screen.about-the-property .section.question .question-description, 
    .screen.about-the-property .section.question .question-description a,
    .screen.about-the-property .section.question .question-description a:visited {
        width: 90%;
        /* margin-top: 13px;
        font-family: Inter;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: -0.18px;
        text-align: center;
        color: #80c0ff;
        max-width: 631px; */
    }

    .screen.about-the-property .section.question .question-description.uploaded {
        display: flex;
        flex-direction: column;
    } 

    .screen.about-the-property .section.question .question-description.uploaded .icons {
        
    }

    .screen.about-the-property .section.question .question-description.uploaded .icons .pi {
        margin: 20px;
        font-size: 30px;
        cursor: pointer;
    }

    .screen.about-the-property .section.question .buttons {
        display: flex;
        flex-direction: row;
    }

    .screen.about-the-property .section.question .buttons .button {
        margin-top: 22px;
        width: 156px;
        height: 46px;
        background-color: #004fc3;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: SpaceGrotesk;
        font-size: 17.5px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 4.54;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
        text-transform: uppercase;
        cursor: pointer;
    }

    .screen.about-the-property .section.question .buttons .button.notset {
        opacity: 0.5;
    }

    .screen.about-the-property .section.question .buttons .button.set {
        opacity: 1;
    }

    .screen.about-the-property .section.question .buttons .button.yes {
        margin-right: 10px;
    }


    .screen.about-the-property .section.question .batteries-detail-question-options {
        margin-top: 0px;
        display: flex;
        flex-direction: column;
        padding-bottom: 30px;
        align-items: center;
    }

    .screen.about-the-property .section.question .batteries-detail-question-options  .batteries-detail-question-option {
        border-right: none;
        border-bottom: 1px solid #80c1ff;
        display: flex;
        flex-direction: column;
        padding: 10px;
        justify-content: flex-start;
        align-items: center;
        width: 80%;
        margin-top: 20px;
    }

    .screen.about-the-property .section.question .batteries-detail-question-options  .batteries-detail-question-option input[type='radio']{
        width: 40px;
        height: 40px;
    }

    .screen.about-the-property .section.question .batteries-detail-question-options  .batteries-detail-question-option:last-child {
        border: none;;
    }

    .screen.about-the-property .section.question .batteries-detail-question-options  .batteries-detail-question-option .label {
        margin-top: 16px;
        font-family: Inter;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: -0.18px;
        text-align: center;
        color: #fff;
    }

    .screen.about-the-property .section.question .batteries-detail-question-options  .batteries-detail-question-option .content {
        margin-top: 16px;
        font-family: Inter;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: -0.18px;
        text-align: center;
        color: #80c0ff;
    }

    .screen.about-the-property .section.question.upload {
        padding-bottom: 50px;
    } 

    .screen.about-the-property .section.question .eletric-bill-fields {
        margin-top: 23px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 57px;
    }

    .screen.about-the-property .section.question .eletric-bill-fields input {
        width: 322px;
        height: 46px;
        font-family: Inter;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.78;
        letter-spacing: -0.2px;
        text-align: left;
        color: #06285e;
    }

    .screen.about-the-property .section.question .eletric-bill-fields .hint {
        margin-top: 23px;
        max-width: 299px;
        font-family: Inter;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: -0.18px;
        text-align: center;
        color: #80c1ff;
    }

    .screen.about-the-property .section.buttons {
        margin-top: 51px;   
        margin-bottom: 51px;   
    }

    .screen.about-the-property .section.buttons .submit-button {
        background-image: url('../../assets/imgs/submit.png');
        background-repeat: no-repeat;
        object-fit: contain;
        width: 322px;
        height: 46px;
        cursor: pointer;
    }

    .screen.about-the-property .section.buttons .error-message {
        margin-top: 12px;
        font-family: Inter;
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: -0.18px;
        text-align: center;
        color: red;
    }
}