.footer {
    display: flex;
    flex-direction: column;
    background-color: #06285e;
    justify-content: center;
    align-items: center;
    padding-top: 52px;
    padding-bottom: 107px;
}

.footer .logo {
    height: 52px;
    width: 63px;
    background-image: url('../../assets/imgs/emblem.png');
    background-repeat: no-repeat;
}

.footer .info {
    margin-top: 9px;
    display: flex;
    flex-direction: column;
}

.footer .info span {
    color: #80c1ff;
    font-family: Inter;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.78;
    letter-spacing: -0.2px;
    text-align: center;
    padding-right: 10px;
    padding-left: 10px;
}

.footer .info span:first-child {
    color: #fff;
}

.footer .info span.divider {
    display: none;
}

.footer .info span a {
    color: #80c1ff;
}

.footer .info span a:visited {
    color: #80c1ff;
}

.footer .links {
    margin-top: 41px;
    display: flex;
    flex-direction: row;
}

.footer .links .link {
    width: 49px;
    height: 48px;
    margin-left: 5px;
    cursor: pointer;
}

.footer .links .link.youtube {
    background-image: url('../../assets/imgs/you-tube.png');
    background-repeat: no-repeat;
}

.footer .links .link.facebook {
    background-image: url('../../assets/imgs/facebook.png');
    background-repeat: no-repeat;
}

.footer .links .link.linkedin {
    background-image: url('../../assets/imgs/linkedin.png');
    background-repeat: no-repeat;
}