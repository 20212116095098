@media screen and (max-width: 1125px) {

    .screen.about-you {
        background-color: #06285e;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .screen.about-you .section.title {
        margin-top: 40px;   
        padding-bottom: 100px;     
        padding-left: 40px;
        padding-right: 40px;
    }

    .screen.about-you.not-arizona .section.title {
        padding-bottom: 0px;
    }

    .screen.about-you.not-arizona .section.title .title {
        display: none;
    }

    .screen.about-you .section.roof-confirmation {
        margin-top: 30px;
        width: 100%;
        border-radius: 5px;
        background-color: #004fc3;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .screen.about-you .section.roof-confirmation #map {
        width: 85vw;
        max-width: 891px;
        height: 200px;
        border-radius: 5px;
        margin-top: -100px;
    }

    .screen.about-you .section.roof-confirmation .this-is-my-roof-button {
        background-image: url('../../assets/imgs/yes-this-is-my-roof-mobile.png');
        background-repeat: no-repeat;
        background-size: contain;
        width: 85.5vw;
        max-width: 891px;
        height: 59px;
        max-height: 99px;
        margin-top: -23px;
        z-index: 100;
        cursor: pointer;
    }

    .screen.about-you .section.roof-confirmation .this-is-not-my-roof-button {
        margin-top: 0px;
        width: 85vw;
    }

    .screen.about-you .section.roof-confirmation .this-is-not-my-roof-reason {
        margin-top: 0px;
        width: 85vw;        
    }

    .screen.about-you .section.roof-confirmation .this-is-not-my-roof-reason span {
        margin-bottom: 17px;
        display: flex;
    }

    .screen.about-you .section.roof-confirmation .this-is-not-my-roof-reason span input[type='radio']{
        width: 20px;
        height: 20px;
        margin-right: 20px;
    }

    .screen.about-you .section.roof-confirmation .this-is-not-my-roof-reason span textarea {
        width: 100%;
    }

    .screen.about-you .section.form {
        margin-top: 36px;
        margin-bottom: 26px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .screen.about-you .section.form .field {
        width: 100%;
    }

    .screen.about-you .section.form .field .p-inputtext {
        width: 100%;
    }

    .screen.about-you .section.form .field input:focus {
        outline: none;
    }

    .screen.about-you .section.form .submit-button {
        object-fit: unset;
        background-size: contain;
        width: 85.5vw;
    }

    .screen.about-you .section.form .error-message {
        margin-top: 12px;
        font-family: Inter;
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: -0.18px;
        text-align: center;
        color: red;
    }

    .screen.about-the-property.not-arizona .section.question.upload {
        margin-bottom: 0px;
        padding-bottom: 0px;
    }

    .screen.about-the-property.not-arizona .section.buttons {
        margin-top: 0px;
    }
}