@media screen and (max-width: 1680px) {
.screen.quote {

}

.screen.quote .section.main {
    padding-left: 45px;
    padding-right: 45px;
    padding-top: 30px;
}

.screen.quote  .section.main .title {
    font-size: 24px;
}

.screen.quote .section.main .widgets {
    margin-top: 15px;
}

.screen.quote .section.main .widgets .widget{
    padding: 24px 24px 16px 24px;
    margin-right: 10px;
}

.screen.quote .section.main .widgets .widget .widget-title {
    font-size: 24px;
    line-height: 1;
}

.screen.quote .section.main .widgets .widget.property-details .address {
    margin-top: 12px;
    font-size: 16px;
}

.screen.quote .section.main .widgets .widget.property-details #map{
    margin-top: 12px;
}

.screen.quote .section.main .widgets .widget.property-details .average-montly-bill {
    margin-top: 12px;
    font-size: 16px;
}

.screen.quote .section.main .widgets .widget.property-details .average-montly-bill-actions {
    font-size: 16px;
}

.screen.quote .section.main .widgets .widget.property-details .average-montly-bill-actions .average-montly-bill-action {
    margin-right: 10px;
}

.screen.quote .section.main .widgets .widget.property-details .average-montly-bill-actions.valuechange {
    margin-top: 12px;
}

.screen.quote .section.main .widgets .widget.property-details .average-montly-bill-actions.valuechange .links {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.screen.quote .section.main .widgets .widget.property-details .savings {
    font-size: 16px;
}

.screen.quote .section.main .widgets .widget.estimated-savings .saving-label {
    margin-top: 12px;
    font-size: 16px;
}

.screen.quote .section.main .widgets .widget.estimated-savings .saving-value {
    font-size: 26px;
}

.screen.quote .section.main .widgets .widget.advisor .advisor-panel .advisor-picture {
    width: 151px;
    height: 151px;
    background-image: url('../../assets/imgs/advisor.png');
    background-repeat: no-repeat;
}

.screen.quote .section.main .widgets .widget.advisor .advisor-panel .advisor-name {
    font-size: 24px;
}

.screen.quote .section.main .widgets .widget.advisor .advisor-message {
    font-size: 16px;
}

.screen.quote .section.main .widgets .widget .widget-buttom {
    font-size: 16px;
}

.screen.quote .section.main .widgets .widget.more .widget-child.next {
    width: 100%;
    padding: 24px 34px 24px 34px;
    flex: 1;
}

.screen.quote .section.main .widgets .widget.more .widget-child.next .content {
    margin-top: 12px;
    font-size: 16px;
}

.screen.quote .section.main .widgets .widget.more .widget-child.password {
    padding: 24px 34px 24px 34px;
    flex: 1;
}

.screen.quote .section.main .widgets .widget.more .widget-child.password .content {
    font-size: 16px;
}

.screen.quote .section.other-offers {
    background-color: #80c1ff;
    margin-top: -420px;
    padding-top: 180px;
    display: flex;
    padding-left: 165px;
    padding-right: 165px;
}

.screen.quote .section.other-offers .offers {
    display: flex;
    flex-direction: column;
    padding-bottom: 61px;
}

.screen.quote .section.other-offers .offers.deal {
    padding-right: 34px;
    border-right: 1px solid #06285e;
}

.screen.quote .section.other-offers .offers.referrals {
    padding-left: 34px;
}

.screen.quote .section.other-offers .offers .title {
    font-family: Inter;
    font-size: 28px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.71;
    letter-spacing: -0.28px;
    text-align: left;
    color: #06285e;
}

.screen.quote .section.other-offers .offers .content {
    display: flex;
    margin-top: 27px;
}

.screen.quote .section.other-offers .offers .content .image {
    margin-right: 24px;
}

.screen.quote .section.other-offers .offers.deal .content .image {
    width: 86px;
    height: 49px;
    background-image: url('../../assets/imgs/deal-icon.png');
    background-repeat: no-repeat;
}

.screen.quote .section.other-offers .offers.referrals .content .image {
    width: 87px;
    height: 47px;
    background-image: url('../../assets/imgs/refer-icon.png');
    background-repeat: no-repeat;
}

.screen.quote .section.other-offers .offers .content .text {
    font-family: Inter;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.18px;
    text-align: left;
    color: #06285e;
}

}