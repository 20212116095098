.screen.about-you {
    background-color: #06285e;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.screen.about-you .section.title {
    margin-top: 50px;
}

.screen.about-you .section.title .title {
    font-family: 'SpaceGrotesk';
    font-size: 37.5px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: -0.38px;
    text-align: center;
    color: #fff;
}

.screen.about-you .section.title .sub-title {
    margin-top: 12px;
    font-family: Inter;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.18px;
    text-align: center;
    color: #80c0ff;
}

.screen.about-you .section.roof-confirmation {
    margin-top: 30px;
    width: 582px;
    border-radius: 29px;
    background-color: #004fc3;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.screen.about-you .section.roof-confirmation #map {
    width: 582px;
    height: 333px;
    border-radius: 29px;
}


.screen.about-you .section.roof-confirmation .this-is-my-roof-button {
    background-image: url('../../assets/imgs/yes-this-is-my-roof.png');
    background-repeat: no-repeat;
    width: 216px;
    height: 46px;
    margin-top: -23px;
    z-index: 100;
    cursor: pointer;
}

.screen.about-you .section.roof-confirmation .this-is-not-my-roof-button {
    margin-top: 17px;
    font-family: Inter;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.18px;
    text-align: center;
    color: #80c0ff;
    cursor: pointer;
    text-decoration: underline;
    margin-bottom: 17px;
}

.screen.about-you .section.roof-confirmation .this-is-not-my-roof-reason {
    margin-top: 0px;
    width: 412px;
    font-family: Inter;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.18px;
    text-align: left;
    color: #80c0ff;
    display: flex;
    flex-direction: column;
}

.screen.about-you .section.roof-confirmation .this-is-not-my-roof-reason .continue-button {
    background-color: #81D553;
    width: 50%;
    margin-left: 25%;
    margin-right: 25%;
    margin-bottom: 20px;
    padding: 8px 16px;
    font-family: Inter;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.78;
    letter-spacing: -0.2px;
    text-align: left;
    color: #06285e;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.screen.about-you .section.roof-confirmation .this-is-not-my-roof-reason span {
    margin-bottom: 17px;
    display: flex;
}

.screen.about-you .section.roof-confirmation .this-is-not-my-roof-reason .continue-button span{
    margin-bottom: unset;    
}

.screen.about-you .section.roof-confirmation .this-is-not-my-roof-reason span input[type='radio']{
    width: 20px;
    height: 20px;
    margin-right: 20px;
}

.screen.about-you .section.roof-confirmation .this-is-not-my-roof-reason span textarea {
    width: 412px;
}

.screen.about-you .section.form {
    margin-top: 26px;
    margin-bottom: 26px;
    display: flex;
    flex-direction: column;
}   

.screen.about-you .section.form .field {
    margin-top: 10px;
    width: 412px;
    height: 46px;
}

.screen.about-you .section.form .field .p-inputtext {
    width: 412px;
    height: 100%;
    font-family: Inter;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.78;
    letter-spacing: -0.2px;
    text-align: left;
    color: #06285e;
    border: none;
    margin: 0;
    border-radius: 0px;
}

.screen.about-you .section.form .field input:focus {
    outline: none;
}

.screen.about-you .section.form .submit-button {
    margin-top: 10px;
    background-image: url('../../assets/imgs/submit-copy.png');
    background-repeat: no-repeat;
    object-fit: contain;
    width: 412px;
    height: 46px;
    cursor: pointer;
}

.screen.about-you .section.form .submit-button.not-arizona {
    background-image: url('../../assets/imgs/submit.png');
    background-position: center;
}

.screen.about-you .section.form .error-message {
    margin-top: 12px;
    font-family: Inter;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.18px;
    text-align: center;
    color: red;
}