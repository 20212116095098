.header {
    height: 161px;
    width: 100%;
    display: flex;
    background-color: #fff;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.header .header-left {
    display: flex;
    align-items: center;
    
}

.header .header-left .elevation-logo {
    height: 37px;
    width: 233px;
    background-image: url('../../assets/imgs/logo-black.png');
    background-repeat: no-repeat;
    margin-left: 105px;
}

.header .header-left .title {
    height: 37px;
    font-family: Inter;
    font-size: 28.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.31px;
    text-align: left;
    color: #0052ca;
    margin-left: 18px;
}

.header .header-right {
    display: flex;
    align-items: center;
    margin-right: 105px;
}

.header .header-right .name {
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.3;
    letter-spacing: -0.3px;
    text-align: right;
    color: #0052ca;
}

.header .header-right .avatar {
    height: 43px;
    width: 43px;
    background-image: url('../../assets/imgs/avatar.png');
    background-repeat: no-repeat;
    margin-left: 18px;
}