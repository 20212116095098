.asu .screen.intro .footer {
    display: flex;
    flex-direction: column;
    background-color: #8b0942;
    justify-content: center;
    align-items: center;
    padding-top: 52px;
    padding-bottom: 107px;
}

.asu .screen.intro .footer .logo {
    height: 95px;
    width: 130px;
    background-image: url('../../assets/imgs/elevation-asu-logos.png');
    background-repeat: no-repeat;
}

.asu .screen.intro .footer .info {
    margin-top: 9px;
    display: flex;
}

.asu .screen.intro .footer .info span {
    font-family: Inter;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.78;
    letter-spacing: -0.2px;
    text-align: center;
    color: #ffc937;
    padding-right: 10px;
    padding-left: 10px;
}

.default .footer .info span:first-child {
    color: #fff;
}

.asu .screen.intro .footer .info span a {
    color: #ffc937;
}

.asu .screen.intro .footer .info span a:visited {
    color: #ffc937;
}

.asu .screen.intro .footer .links {
    margin-top: 41px;
    display: flex;
    flex-direction: row;
}

.asu .screen.intro .footer .links .link {
    width: 49px;
    height: 48px;
    margin-left: 5px;
    cursor: pointer;
}

.asu .screen.intro .footer .links .link.youtube {
    background-image: url('../../assets/imgs/you-tube-asu.png');
    background-repeat: no-repeat;
}

.asu .screen.intro .footer .links .link.facebook {
    background-image: url('../../assets/imgs/facebook-asu.png');
    background-repeat: no-repeat;
}

.asu .screen.intro .footer .links .link.linkedin {
    background-image: url('../../assets/imgs/linkedin-asu.png');
    background-repeat: no-repeat;
}