.loader {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 200px;
}

.loader .logo {
    height: 37px;
    width: 233px;
    background-image: url('../../assets/imgs/logo-black.png');
    background-repeat: no-repeat;
}

.loader .progress {
 margin-top: 10px;
 width: 233px;
 height: 100px;
}