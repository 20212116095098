@font-face {
  font-family: 'SpaceGrotesk';
  src: local('SpaceGrotesk'), url(./assets/fonts/SpaceGrotesk-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(./assets/fonts/Inter-VariableFont_slnt_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'InterSemiBold';
  src: local('Inter'), url(./assets/fonts/Inter-SemiBold.ttf) format('truetype');
}


body {
  background-color: #fff;
  width: 100%;
}

.App {
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
}

.screen {
  background-color: #fff;
  width: 100%;
  max-width: 1920px;
  height: 100%;
  min-height: 100vh;
}

.section {
  
}

.p-inputtext {
  padding-left: 18px;
}

textarea:focus, input:focus, button:focus{
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

input:enabled:focus, textarea:enabled:focus, select:enabled:focus, button:enabled:focus{
  outline: none;
}