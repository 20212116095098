@media screen and (max-width: 1125px) {

    .header {
        height: 100px;
        width: 100%;
        display: flex;
        background-color: #fff;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        padding-bottom: 30px;
    }

    .header .header-left {
        
    }

    .header .header-left .elevation-logo {
        height: 25px;
        width: 156px;
        background-size: contain;
        margin-left: 30px;
    }

    .header .header-left .title {
        height: 24.6px;
        font-size: 24px;
    }

    .header .header-right {
        display: none;
    }
}